<template  lang="pug" >
    v-row 
        v-col(cols="12")
            p( class="text-body-2 mb-7") 
                | Elegir entre 0.5 y 0.9,  entre más alto más restrictivo es el radio de coincidencia entre las palabras clave y los niveles de categoría del catálogo Coppel.  <br>
                | Por default utiliza 0.8 que ha sido utilizado en pruebas y ha demostrado ser adecuado. <br>
                | Impacta sólo sobre las lógicas. Dejar en blanco si no se quiere modificar el valor por default.

            p( class="text-body-2 mb-5") Propensiones
            
            v-divider(class='mb-0')
        v-col(cols="4")
            v-row 
                v-col(cols="12")
                    p( class="text-body-2 mb-0 blue-500--text") Busquedas:
                v-col(cols="12")
                    ValidationProvider(v-slot='{ errors }' name='categoria' rules='required')
                        v-select( 
                            :items="propension" 
                            item-text="text"
                            item-value="value"
                            label="Categoría"
                            v-model='form.prop_busqueda_cat'
                            :hint="`Categoría ${form.prop_busqueda_cat}`"
                            :error-messages='errors'
                            small-chips)

                v-col(cols="12")
                    v-row(class="align-baseline")
                        v-col(cols="12")
                            ValidationProvider(v-slot='{ errors }' name='subcategoria' rules='required')
                                v-select( 
                                    :items="propension" 
                                    item-text="text"
                                    item-value="value"
                                    label="Subcategoría"
                                    v-model='form.prop_busqueda_subcat'
                                    :hint="`Subcategoría ${form.prop_busqueda_subcat}`"
                                    :error-messages='errors'
                                    small-chips)
                v-col(cols="12")
                    v-row(class="align-baseline")
                        v-col(cols="1")
                            v-radio-group(v-model='form.flag_clases' )
                                v-radio(
                                    :value="true"
                                    dense  
                                    class="pt-0 mt-0 pb-0 mb-0")
                        v-col(cols="10")
                            ValidationProvider(v-slot='{ errors }' name='clase' rules='required')
                                v-select( 
                                    :items="propension" 
                                    item-text="text"
                                    item-value="value"
                                    label="Clase"
                                    :disabled="!form.flag_clases"
                                    v-model='form.prop_busqueda_clase'
                                    :hint="`Clase ${form.prop_busqueda_clase}`"
                                    :error-messages='errors'
                                    small-chips)
                v-col(cols="12")
                    v-row(class="align-baseline")
                        v-col(cols="1")
                            v-radio-group(v-model='form.flag_clases' )
                                v-radio(
                                    :value="false"
                                    dense  
                                    class="pt-0 mt-0 pb-0 mb-0")
                        v-col(cols="11")
                            ValidationProvider(v-slot='{ errors }' name='Triple, nivel clase' rules='required')
                                v-select( 
                                    :items="propension" 
                                    item-text="text"
                                    item-value="value"
                                    label="Triple, nivel clase"
                                    v-model='form.prop_triple'
                                    :disabled="form.flag_clases"
                                    :hint="`Triple, nivel clase ${form.prop_triple}`"
                                    :error-messages='errors'
                                    small-chips)


        v-col(cols="4")
            v-row 
                v-col(cols="12")
                    p( class="text-body-2 mb-0 blue-500--text") Vistas:
                v-col(cols="12")
                    ValidationProvider(v-slot='{ errors }' name='categoria' rules='required')
                            v-select( 
                                :items="propension" 
                                item-text="text"
                                item-value="value"
                                label="Categoría"
                                v-model='form.prop_compra_cat'
                                :hint="`Categoría ${form.prop_compra_cat}`"
                                :error-messages='errors'
                                small-chips)

                v-col(cols="12")
                    v-row(class="align-baseline")
                        v-col(cols="12")
                            ValidationProvider(v-slot='{ errors }' name='subcategoria' rules='required')
                                v-select( 
                                    :items="propension" 
                                    item-text="text"
                                    item-value="value"
                                    label="Subcategoría"
                                    v-model='form.prop_compra_subcat'
                                    :hint="`Subcategoría ${form.prop_compra_subcat}`"
                                    :error-messages='errors'
                                    small-chips)
                v-col(cols="12")
                    v-row(class="align-baseline")
                        v-col(cols="12")
                            ValidationProvider(v-slot='{ errors }' name='clase' rules='required')
                                v-select( 
                                    :items="propension" 
                                    item-text="text"
                                    item-value="value"
                                    label="Clase"
                                    v-model='form.prop_compra_clase'
                                    :hint="`Clase ${form.prop_compra_clase}`"
                                    :error-messages='errors'
                                    small-chips)

        v-col(cols="4")
            v-row 
                v-col(cols="12")
                    p( class="text-body-2 mb-0 blue-500--text") Compras:
                v-col(cols="12")
                    ValidationProvider(v-slot='{ errors }' name='categoria' rules='required')
                            v-select( 
                                :items="propension" 
                                item-text="text"
                                item-value="value"
                                label="Categoría"
                                v-model='form.prop_vista_cat'
                                :hint="`Categoría ${form.prop_vista_cat}`"
                                :error-messages='errors'
                                small-chips)

                v-col(cols="12")
                    v-row(class="align-baseline")
                        v-col(cols="12")
                            ValidationProvider(v-slot='{ errors }' name='subcategoria' rules='required')
                                v-select( 
                                    :items="propension" 
                                    item-text="text"
                                    item-value="value"
                                    label="Subcategoría"
                                    v-model='form.prop_vista_subcat'
                                    :hint="`Subcategoría ${form.prop_vista_subcat}`"
                                    :error-messages='errors'
                                    small-chips)
                v-col(cols="12")
                    v-row(class="align-baseline")
                        v-col(cols="12")
                            ValidationProvider(v-slot='{ errors }' name='clase' rules='required')
                                v-select( 
                                    :items="propension" 
                                    item-text="text"
                                    item-value="value"
                                    label="Clase"
                                    v-model='form.prop_vista_clase'
                                    :hint="`Clase ${form.prop_vista_clase}`"
                                    :error-messages='errors'
                                    small-chips)

        v-col(cols="12")
            p( class="text-body-2 mb-5") 
                | Modelos de segmentación de clientes
            v-divider(class='mb-0')

        v-col(cols="4")
            v-row
                v-col(cols="12")
                    ValidationProvider(v-slot='{ errors }' name='score recompra' rules='required')
                        v-select( 
                            :items="propension" 
                            item-text="text"
                            item-value="value"
                            label="Score  Recompra"
                            v-model='form.scoreRecompra'
                            :hint="`Score ${form.scoreRecompra}`"
                            :error-messages='errors'
                            small-chips)
        v-col(sm="12")
            v-row
                v-col(sm="12")
                    p(class="text-body-2 mb-0") RFM
                    
                v-col(sm="4")
                    p(class="text-subtitle-1 blue-600--text mb-0") R: Recencia
                    label(class="text-caption") Tiempo transcurrido desde su última compra
                    v-checkbox(
                            v-for="(item,index) of [1,2,3,4]"
                            :key="`recency-${index}`"
                            :label="''+item"
                            v-model="form.R_lst"
                            outlined
                            :value="item")
                v-col(sm="4")
                    p(class="text-subtitle-1 blue-600--text mb-0") F: Frecuencia
                    label(class="text-caption") Número  de compras.
                    v-checkbox(
                        v-for="(item,index) of [1,2,3,4]"
                        :key="`frecuency-${index}`"
                        :label="''+item"
                        v-model="form.F_lst"
                        outlined
                        :value="item")
                v-col(sm="4")
                    p(class="text-subtitle-1 blue-600--text mb-0") M: Monetario
                    label(class="text-caption") Valor de las compras totales del cliente.
                    v-checkbox(
                        v-for="(item,index) of [1,2,3,4]"
                        :key="`monetary-${index}`"
                        :label="''+item"
                        v-model="form.M_lst"
                        outlined
                        :value="item")
        v-col(cols="12")
            p( class="text-body-2 mb-5") 
                | Interacciones y comportamiento del cliente
            v-divider(class='mb-0')
        
        v-col(sm="12")
            v-row(align="end")
                v-col(cols="4")
                    ValidationProvider(v-slot='{ errors }' name='busquedas y vistas' rules='required')
                        v-text-field(label='Intervalo días búsquedas y vistas' v-model='form.intervalo_dias' type="number" step="1" min="1" :error-messages='errors')
                v-col(cols="4")
                    ValidationProvider(v-slot='{ errors }' name='Canal vistas, búsquedas, órdenes y carrito' rules='required')
                        v-select( 
                            :items="['APP COPPEL', 'COPPEL.COM']" 
                            label="Canal vistas, búsquedas, órdenes y carrito"
                            v-model='form.canales_lst'
                            multiple 
                            small-chips
                            :error-messages='errors')
                v-col(cols="12")
                        v-row 
                            v-col(cols="4")
                                p( class="text-body-2 mb-0") Temporalidad extra
                                v-row(class="align-baseline")
                                    v-col(cols="2")
                                        v-checkbox(
                                            v-model="form.flag_temporalidades_extra"
                                            @change="()=>form.temporalidades_extra = '' "
                                            outlined)
                                    v-col(cols="9")
                                        ValidationProvider(v-slot='{ errors }' name='Temporalidad extra' rules='validate_MM_YYYY')
                                            v-text-field(label='MM AAAA' v-model='form.temporalidades_extra' :error-messages='errors' :disabled="!form.flag_temporalidades_extra")
                                    v-col(cols="1")
                                        v-tooltip( top )
                                            template( v-slot:activator="{ on }")
                                                v-btn( icon v-on="on" x-small class="blue-600")
                                                    v-icon( color="white") mdi-help
                                            span Temporalidad extra en formato MM AAAA. Para más de una separar por ,
                v-col(cols="12")
                    v-row 
                        v-col(cols="4")
                            p(class="text-body-2  mb-0") Nivel de MKP
                            ValidationProvider(v-slot='{ errors }' name='Nivel de MKP' rules='required')
                                v-radio-group(v-model="form.nivel_mkp" dense :error-messages='errors')
                                    v-radio(label="Primer Nivel" value="des_CategoriaPrimerNivel")
                                    v-radio(label="Segundo Nivel" value="des_CategoriaSegundoNivel")
        template( v-if="form.flag_especial_fds")
            v-col(cols="12")
                p( class="text-body-2 mb-5") 
                    | Modelos extras
                v-divider(class='mb-0')
            
            v-col(sm="12")
                v-row                                                            
                    v-col(sm="4")
                        p(class="text-subtitle-1 blue-600--text mb-0") SPC
                        label(class="text-caption") Flags SPC
                        ValidationProvider(v-slot='{ errors }' name='SPC')
                            v-checkbox(
                                    v-for="(item,index) of ['C1', 'C2', 'C3', 'C4']"
                                    :key="`recency-${index}`"
                                    :label="''+item"
                                    v-model="form.spc_lst"
                                    outlined
                                    :value="item")
                    v-col(cols="4")
                        ValidationProvider(v-slot='{ errors }' name='Score por canal' rules='required')
                            v-select( 
                                :items="propension" 
                                item-text="text"
                                item-value="value"
                                label="Score por canal"
                                v-model='form.scoreCanal'
                                :hint="`Score por canal ${form.scoreCanal}`"
                                :error-messages='errors'
                                chips)

</template>
<script>
export default {
    name: 'PushFormModels',
    props: ['form'],
    data() {
        return {
            propension: [
                {text: '0.5', value: '>=0.5'},
                {text: '0.6', value: '>=0.6'},
                {text: '0.7', value: '>=0.7'},
                {text: '0.8', value: '>=0.8'},
                {text: '0.9', value: '>=0.9'},
                {text: '1', value: '>=1'},
            ],
        }
    }
}
</script>
